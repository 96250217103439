import { db } from '@/firebase/config'

const createNewChat = async (chatterId, myId) => {
  let chatId
  try {
    await db.collection('Messages').add({
      chatterIds: [chatterId, myId],
      unreadCount: [0, 0],
      unreadNotice: [false, false]
    }).then(doc => {
      chatId = doc.id
    });
    // console.log("createNewChat activated");
  } catch (error) {
    alert("createNewChat " + error);
  }
  return chatId
};

export default createNewChat
