import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

const sendChat = async (chatId, content, position, now) => {
  try {
    const unreadArr = [false, false];
    unreadArr[0] = position ? true : false;
    unreadArr[1] = position ? false : true;
    
    await db.collection('Messages').doc(chatId).collection('history').add({
      content: content,
      senderID: position,
      time: now
    });
    await db.collection('Messages').doc(chatId).get().then(async (doc)=>{
      if(doc.data().chatterIds[0] !== doc.data().chatterIds[1]){
        await db.collection('Profiles').doc(doc.data().chatterIds[position ? 0 : 1]).collection("myUnreadMsgs").doc("unreadMsgs").update({
          unreadMsgs: firebase.firestore.FieldValue.increment(1)
        })
        let unreadCountArr = [0, 0]
        if(doc.data().unreadCount){
          unreadCountArr = doc.data().unreadCount
        }
        unreadCountArr[position ? 0 : 1]++
        await doc.ref.update({
          unreadNotice: unreadArr,
          unreadCount: unreadCountArr,
        })
      }
    })
  } catch (error) {
    alert("sendChat " + error);
  }
};

export default sendChat
